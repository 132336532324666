import { createApp } from '../app'

import * as Sentry from '@sentry/vue'
import { Confetti } from 'vue-confetti'
import VueSilentbox from 'vue-silentbox'
import 'vue-silentbox/dist/style.css'

export { render as onRenderClient }

let app
async function render(pageContext) {
  // Hydration after SSR
  if (pageContext.isHydration) {
    const instance = createApp(pageContext)
    app = instance.app
    instance.store.state.value = pageContext.initialStoreState

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      environment: import.meta.env.VITE_SENTRY_ENV,
      logErrors: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: import.meta.env.PROD ? 0.1 : 1.0,
    })

    // Only works on client-side, so we need to add it here
    app.provide('confetti', new Confetti())
    app.use(VueSilentbox)

    app.mount('#jrCB')
  } else {
    app.changePage(pageContext)
  }
}
